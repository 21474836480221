export const AllCategories = [
  
  {
    name: "Pizza",
    id: "Pizza",
  },
  {
    name: "Pasta",
    id: "Pasta",
  },
  {
    name: "Lasagna",
    id: "Lasagna",
  },
  {
    name: "Garlic Fingers",
    id: "Garlic Fingers",
  },
  {
    name: "Salad",
    id: "Salad",
  },
  {
    name: "Donair Special",
    id: "Donair Special",
  },
];
