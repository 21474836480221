import React from 'react'

const FooterContact = () => {
  return (
    <section className="footer__contact flex-container flex-column">
      <h3>Have questions?</h3>
      <p>506-386-7000</p>
      <p>Info.bravopizza@gmail.com</p>
     
    </section>
  )
}
export default FooterContact;
